@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap);
*{
    font-family: 'Inter';
}

body{

    margin: 0;
    background-color: #222831;
}

main{

    width: 100%;
    background-color: #222831;
    padding-top: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

header{

    width: 80%;
}

.metronomeModal{

    margin-top: 2vh;
    width: 300px;
    background-color: #393E46;
    padding: 30px;
    border-radius: 6px;
}

svg > rect{

    cursor: pointer;
}

.metronomeButton > button{

    position: absolute;
    top: 91vh;
    left: 95vw;
    cursor: pointer;
    width: 60px;
    height: 60px;
    color: #FD7014;
    border: 1px #393E46 solid;
    background-color: #222831;
    border-radius: 50%;
    font-size: 40px;
    box-shadow: 10px 10px 25px -6px rgba(0,0,0,0.55);
    -webkit-box-shadow: 10px 10px 25px -6px rgba(0,0,0,0.55);
    -moz-box-shadow: 10px 10px 25px -6px rgba(0,0,0,0.55);
}

.downloadWrapper{

    display: -webkit-flex;

    display: flex;
    width: 700px;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 15vh;
    margin-bottom: 5vh;
}

.downloadText{

    margin-top: 10vh;
    color: #EEEEEE;
    font-size: 24px;
}

.downloadWrapper button{

    font-size: 24px;
    border: 1px solid #FD7014;
    cursor: pointer;
    font-weight: bold;
    background-color: #393E46;
    border-radius: 6px;
    height: 100px;
    color: #EEEEEE;
    width: 190px;
}

.textScale{

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    width: 40%;
    margin-top: 14vh;
    color: #EEEEEE;
}

.textScale h1{

    border: #FD7014 1px solid;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.logo{

    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.nav{

    margin-top: 40px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
}

.select-group{

    width: 20%;
}

#instrument-chord-finder{

    width: 90%;
    margin-bottom: 30px;
}

.select-chord-finder{

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 30%;
}

#bold-label{

    font-weight: bold;
}

.select-chord-finder > label{

    color: #EEEEEE;
    font-weight: 500;
    font-size: 20px;
}

.select-group > label{

    color: #EEEEEE;
    font-weight: bold;
    font-size: 20px;
}

.react-select-container{

    background-color: #222831;
}

.mainKeys,
.mainGuitar{

    margin-top: 14vh;
}

.mainGuitarChordFinder{

    margin-top: 7vh;
}

.mainGuitar,
.mainGuitarChordFinder{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.mainGuitar img{

    z-index: 0;
}

.fingerboardNotes > rect{

    fill: #393E46;
}

#fingerboardScale{

    position: absolute;
    z-index: 1;
    margin-right: 20px;
}

footer{

    position: relative;
    top: 26vh;
}

footer > label{

    font-family: 'IBM Plex Mono';
    color: #EEEEEE;
    cursor: pointer;
    opacity: 0.45;
}

#WIP{

    margin-top: 20vh;
    color: #EEEEEE;
}

.guitarWrapper{

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.guitarWrapperChordFinder{

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.fretboardWrapper{

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.chordName{

    color: #EEEEEE;
    font-size: 24px;
    font-weight: 600;
    margin-top: 2vh;
}

.tuningLabel{

    margin-top: 14vh;
    margin-right: 30px;
    color: #EEEEEE;
    font-size: 10px;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer !important;
}

.inScale{

    color: #FD7014;
}

.bm-item:hover{

    color: #FD7014 !important;
    background-color: #222831 !important;
}

.menu-icon{

    margin-right: 10px;
    font-size: 25px;
}
